import ReactDOM from "react-dom/client";
import "./styles/globals.scss";
import App from "./App";
const root = ReactDOM.createRoot(
  document.getElementById("raiff-demo-artw") as HTMLElement
);

root.render(
    <App />
);
